/* -----------------------------------------------
				            CSS Styles
--------------------------------------------------

    Author: Malyarchuk
    Copyright: 2022

--------------------------------------------------

Table of Content

	1. Body and Core Css
     - 1.1 Typography
     - 1.2 Button Style
     - 1.4 Form Style
     - 1.5 NavBar
     - 1.6 Modal
     - 1.7 Slider

	2. Home Page
     - 2.1 Banner Section
     - 2.2 Company Section
     - 2.3 Fees Section
     - 2.4 Our Help Section
     - 2.5 Donat Section
     - 2.6 Help Section
     - 2.7 Request Section
     - 2.8 Contact Section

  3. About Page
  4. News Page
  5. NFT Page
  6. Footer
  7. Page Banner

----------------------------------- */

/* -----------------------------------
		    1. Body and Core Css
----------------------------------- */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;

  &:after,
  &:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font: {
    size: 14px;
    weight: 500;
    family: "Nunito", sans-serif;
  }
  line-height: 1.3;
  text-align: center;
  color: #444444;
  background: #fff;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 1.1 Typography */
a {
  color: #43bfee;
  outline: none;
  text-decoration: none;

  &:hover {
    color: #43bfee;
    outline: none;
    text-decoration: none;
  }
}

p {
  color: #444444;
  font: {
    size: 14px;
    weight: 600;
  }
  line-height: 1.7;
}

img {
  max-width: 100%;
}

dl,
ol,
ul {
  padding: 0;
}

.title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  margin-bottom: 80px;

  svg {
    width: 20px;
    height: 20px;
    color: #37a9d6;
    padding: 20px;
    border-radius: 50%;
    background-color: #f2fbfd;
    margin-bottom: 30px;
  }
}

/* 1.2 Button Style */
.btn-st {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #fff;
  font: {
    size: 16px;
    weight: 600;
  }
  border: none;
  border-radius: 5px;
  margin-right: 15px;
  border: 2px solid transparent;
  background-color: #37a9d6;
  transition: all 0.3s;

  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  &.transparent {
    border: 2px solid #37a9d6;
    background-color: transparent;

    &:hover {
      color: #37a9d6;
    }
  }
}

.btn-groups {
  display: flex;
  align-items: center;
}

/* 1.4 Form Style */
.input-group {
  text-align: left;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.05);

  input,
  select,
  textarea {
    color: #444;
    height: 50px;
    font-size: 13px;
    padding: 0 20px;
    font-weight: 400;
    border: none;
    border-radius: 10px;
    background-color: #fff;
  }

  textarea {
    padding: 20px;
    height: 100px;
    resize: none;
  }

  .input-group-text {
    position: relative;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;

    svg {
      width: 14px;
      height: 14px;
      opacity: 0.8;
    }

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      right: 1px;
      background-color: #ecf7fa;
    }
  }
}

.valid {
  position: absolute;
  width: 100%;
  bottom: 80px;
  left: 50%;
  padding: 0 20px;
  transform: translateX(-50%);

  .invalid-feedback {
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    text-align: left;
    z-index: 2;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 7px;
    }

    &.active {
      display: flex;
    }

    &.success,
    &.success-req {
      color: #47d071;
    }
  }
}

/* 1.5 NavBar */
.top-bar {
  position: relative;
  top: 0;
  height: 50px;

  background-color: #000;

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    ul {
      display: flex;
      align-items: center;
      gap: 15px;
      margin: 0;
      padding: 0;

      li {
        a {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #fff;
          transition: all 0.3s;

          svg {
            color: #37a9d6;
          }

          &:hover {
            color: #37a9d6;
          }
        }
      }
    }

    .social {
      ul {
        li {
          a {
            font-size: 16px;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}

.navbar-custom {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  top: 50px;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #4e4e4e;
  transition: all 0.3s;
  z-index: 9999;

  .navbar-brand {
    img {
      max-width: 150px;
    }
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    margin: auto;
    padding: 0;

    a {
      &.link {
        position: relative;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        padding: 40px 20px;
        text-transform: uppercase;
        transition: all 0.3s;

        &.active {
          color: #37a9d6;

          &::after {
            content: "";
            position: absolute;
            width: calc(100% - 30px);
            height: 4px;
            left: 50%;
            bottom: -2px;
            border-radius: 5px;
            background-color: #37a9d6;
            transform: translateX(-50%);
          }
        }

        &:hover {
          color: #37a9d6;
        }
      }
    }
  }

  .dropdown-toggle {
    &.nav-link {
      color: #fff;
      font: {
        size: 14px;
        weight: 600;
      }
      padding: 10px 15px 10px 35px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.7);
      border: 1px solid rgba(77, 77, 77, 0.57);
      transition: all 0.3s;

      &:hover,
      &:focus {
        color: #37a9d6;
        background-color: #000;
      }
    }

    &::after {
      opacity: 1;
      vertical-align: 0.1em;
      border-top: 0.35em solid;
      transition: transform 0.3s;
    }

    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      top: 50%;
      left: 12px;
      background-size: cover;
      background-image: url("../../img/icon/earth.svg");
      transform: translateY(-50%);
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    font: {
      size: 14px;
      weight: 600;
    }
    color: #fff;
    padding: 5px;

    img {
      max-width: 15px;
      margin-right: 7px;
      border-radius: 5px;
    }

    &:active,
    &:hover,
    &:focus {
      color: #fff;
      border-radius: 3px;
      background-color: #3b3b3b;
    }
  }

  .dropdown-menu {
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(77, 77, 77, 0.57);
  }

  .dropdown.show {
    .dropdown-toggle {
      &::after {
        transform: rotateZ(180deg);
      }
    }
  }
}

/* 1.6 Modal */
.modal-body {
  padding: 20px;

  p {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
  }

  img {
    max-width: 150px;
  }

  .input-group {
    border: 1px solid #fafafa;

    input {
      font-size: 16px;
      background-color: #fff;
    }

    .input-group-text {
      cursor: pointer;
    }
  }

  .transfer {
    font-family: "Caveat", sans-serif;
  }

  .thank {
    padding: 15px 10px;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 20px;
    border: 1px solid #e9e9e9;
  }
}

.modal-order {
  h5 {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
    font-weight: 600;

    svg {
      color: #fff;
      width: 25px;
      height: 25px;
      padding: 7px;
      margin-bottom: 10px;
      border-radius: 5px;
      background-color: #47d071;
    }

    span {
      color: #37a9d6;
      font-size: 31px;
      font-family: "Caveat", sans-serif;
      margin-bottom: 5px;
    }
  }
}

/* 1.7 Slider */
.slick-slider {
  position: relative;
  top: 100px;

  .slid {
    position: relative;
    padding: 20px;

    .item {
      padding: 15px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);

      .img {
        width: 100%;
        height: 280px;
        border-radius: 5px;
        background-size: cover;
        background-position: center center;
      }
    }
  }

  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
    top: unset;
    right: unset;
    bottom: 5px;
    border-radius: 5px;
    background: #37a9d6;
    transition: all 0.3s;

    &:hover {
      background: #9bd4ea;
    }
  }

  .slick-prev {
    left: -125px;
  }

  .slick-next {
    left: -65px;
  }

  .slick-prev::before,
  .slick-next::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    background-image: url(../../img/icon/angle-left.svg);
    background-size: cover;
    transform: translate(-50%, -50%);
  }

  .slick-next::before {
    background-image: url(../../img/icon/angle-right.svg);
  }
}

/* ------------------------------
		    2. Home Page
------------------------------ */
/* 2.1 Banner Section */
.banner {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  padding-top: 150px;
  background-size: cover;
  background-image: url(../../img/backgrounds/banner_bg.jpg);
  background-position: center center;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .container {
    position: relative;
    z-index: 1;

    h1 {
      color: #fff;
      font: {
        size: 55px;
        weight: 700;
      }
      text-align: left;

      span {
        color: #37a9d6;
        font: {
          size: 40px;
          family: "Caveat", sans-serif;
        }
      }
    }

    p {
      color: #fff;
      font: {
        size: 15px;
        weight: 600;
      }
      margin: 40px 0;
      text-align: left;
    }
  }

  &.v2 {
    h1 {
      font-size: 25px;
    }

    h2 {
      font-size: 20px;
      margin-bottom: 30px;
    }

    p {
      color: #626262;
      text-align: left;
    }

    img {
      max-width: 400px;
    }
  }
}

/* 2.2 Company Section */
.company {
  position: relative;
  padding: 100px 0 50px;

  .widget {
    position: relative;

    a {
      img {
        margin: 0 15px;
        max-width: 40%;
        border-radius: 10px;
        transition: transform 0.3s;

        &:nth-child(1) {
          margin-top: 30px;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    p {
      position: absolute;
      display: flex;
      align-items: center;
      right: 50px;
      bottom: 0px;
      font: {
        size: 16px;
        weight: 600;
      }
      padding: 15px;
      text-align: left;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

      span {
        display: flex;
        flex-direction: column;
        font: {
          size: 20px;
          weight: 800;
        }

        b {
          color: #777;
          font: {
            size: 15px;
            weight: 600;
          }
        }
      }

      svg {
        width: 20px;
        height: 20px;
        color: #fff;
        padding: 15px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: #37a9d6;
        transition: all 0.3s;
      }

      &:hover {
        svg {
          color: #37a9d6;
          background-color: #000;
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 60%;
      height: 80%;
      top: 50%;
      left: 50%;
      border-radius: 15px;
      border: 2px dashed #37a9d6;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }

  .info {
    h3 {
      display: flex;
      align-items: center;
      font-weight: 600;
      text-align: left;
      margin-bottom: 30px;

      img {
        max-width: 18px;
        margin-right: 10px;
      }
    }

    h4 {
      position: relative;
      font-weight: 600;
      text-align: left;
      margin: 20px 0;

      &::after {
        content: "";
        position: absolute;
        width: 49px;
        height: 20px;
        top: 12px;
        left: 0;
        border-radius: 50%;
        background: #37a9d6;
        transform: translateY(-50%) rotate(-7deg);
        z-index: -1;
        opacity: 0.2;
      }
    }

    p {
      text-align: left;
      // margin-bottom: 37px;
    }

    .mission {
      display: flex;

      .item {
        width: 50%;
        margin-right: 15px;

        h4 {
          color: #37a9d6;
          font: {
            family: "Caveat", sans-serif;
          }
          text-align: left;
        }

        p {
          text-align: left;
        }
      }
    }

    .work {
      padding: 15px 0;

      span {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        svg {
          width: 22px;
          height: 22px;
          color: #000;
          padding: 15px;
          margin-right: 14px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
          transition: all 0.3s;
        }

        p {
          margin: 0;
        }

        &:last-child {
          margin: 0;
        }

        &:hover {
          svg {
            color: #37a9d6;
          }
        }
      }
    }

    .flag {
      max-width: 20px;
      margin-right: 5px;
    }
  }

  .video {
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #ebebeb;

    iframe {
      border-radius: 10px;
    }
  }
}

/* 2.3 Fees Section */
.fees {
  position: relative;
  padding: 50px 0;
  background-image: url(../../img/backgrounds/fees_bg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 50px);
    padding: 10px 20px;
    margin-bottom: 70px;
    border-radius: 10px;
    background-color: #ecf7fa;

    h4 {
      font-weight: 800;
      padding: 15px;
      margin: -35px 20px 30px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    }

    p {
      font-size: 16px;
      margin: 0;
      text-transform: uppercase;
    }
  }
}

/* 2.4 Our Help Section */
.our-help {
  position: relative;
  padding: 200px 0;
  margin-bottom: 130px;
  background-image: url(../../img/backgrounds/our_bg.png);
  background-size: cover;
  background-position: center center;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #070e20cf;
  }

  .container {
    position: relative;
    z-index: 1;

    .left-side {
      position: relative;
      padding: 50px;
      text-align: left;
      border-radius: 30px;

      h2 {
        color: #37a9d6;
        font: {
          size: 40px;
          family: "Caveat", sans-serif;
        }
        text-align: left;
        margin-bottom: 30px;
      }

      p {
        color: #fff;
        text-align: left;
        margin-bottom: 30px;
      }
    }
  }
}

/* 2.5 Donat Section */
.donat,
.k-donat {
  position: relative;
  padding: 50px 0 130px;
  background-image: url(../../img/backgrounds/donat_bg.png);
  background-size: cover;
  background-position: center center;

  .points {
    span {
      position: absolute;
      animation: fadeIn 7s alternate infinite ease-in;

      &:nth-child(1) {
        top: 42%;
        left: 28%;
      }

      &:nth-child(2) {
        bottom: 18%;
        left: 37%;

        img {
          width: 25px;
          height: 25px;
        }
      }

      &:nth-child(3) {
        top: 37%;
        left: 47%;

        img {
          width: 20px;
          height: 20px;
        }
      }

      &:nth-child(4) {
        top: 43%;
        right: 26%;

        img {
          width: 20px;
          height: 20px;
        }
      }

      &:nth-child(5) {
        bottom: 22%;
        right: 27%;
      }
    }
  }

  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        max-height: 40px;
        margin-bottom: 25px;
      }

      p {
        font-size: 19px;
        margin-bottom: 25px;
      }
    }

    .btn-st {
      margin: 0;
      padding: 7px;

      svg {
        width: 15px;
        height: 15px;
        margin-right: 7px;
        animation: scale 1s alternate infinite ease-in;
      }
    }
  }
}

/* 2.6 Help Section */
.help {
  position: relative;
  padding-bottom: 50px;

  .form-custom {
    position: relative;
    padding: 20px;
    border-radius: 10px;
    background-color: #ecf7fa;

    &::after {
      content: "";
      position: absolute;
      width: 305px;
      height: 280px;
      top: 50%;
      right: -80px;
      background: #ecf7fa;
      border-top-right-radius: 25%;
      border-bottom-left-radius: 25%;
      transform: rotate(62deg) skewX(35deg) scale(1, 0.92) translateY(-50%);
      z-index: -1;
    }

    .btn-st {
      margin: 20px auto 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    p {
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 5px;
      text-align: left;
      line-height: 1.2;
      margin-bottom: 30px;

      svg {
        color: #fff;
        width: 25px;
        height: 25px;
        padding: 7px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #47d071;
      }
    }
  }

  ul {
    padding-left: 30px;

    li {
      text-align: left;
      margin-bottom: 50px;

      h5 {
        display: flex;
        align-items: center;
        font-weight: 600;

        svg {
          color: #47d071;
          width: 15px;
          height: 15px;
          padding: 8px;
          margin-right: 10px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
        }
      }

      p {
        font-size: 14px;
        padding-left: 43px;
      }

      &:nth-child(2),
      &:nth-child(4) {
        padding-left: 60px;
      }

      &:nth-child(3) {
        padding-left: 100px;
      }
    }
  }
}

/* 2.7 Request Section */
.request {
  position: relative;
  padding: 20px 0 0;

  .bg {
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

    .info {
      padding: 20px;
      text-align: left;

      p {
        display: flex;
        align-items: center;
        font-size: 15px;
        padding: 5px;
        text-align: left;
        line-height: 1.2;
        margin-bottom: 30px;

        svg {
          color: #fff;
          width: 25px;
          height: 25px;
          padding: 7px;
          margin-right: 10px;
          border-radius: 5px;
          background-color: #47d071;
        }
      }

      span {
        font: {
          size: 18px;
          weight: 600;
        }
        margin-bottom: 10px;
      }

      .icon {
        display: flex;
        align-items: center;
        margin: 15px 0;

        a {
          font: {
            size: 15px;
            weight: 600;
          }
          transition: all 0.3s;
        }

        svg {
          width: 15px;
          height: 15px;
          padding: 10px;
          margin-right: 14px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
          transition: all 0.3s;
        }

        &:hover {
          a {
            color: #000;
          }

          svg {
            color: #37a9d6;
          }
        }
      }
    }

    form {
      position: relative;
    }

    .form {
      padding: 20px;
      background-color: #ecf7fa;

      p {
        font-size: 15px;
      }

      .btn-st {
        margin: 20px auto 0;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .valid {
        padding: 0 10px;
        bottom: 60px;
      }
    }
  }
}

/* 2.8 Contact Section */
.contact {
  padding: 100px 0;

  .info {
    position: relative;
    height: calc(100% - 30px);
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #ecf7fa;

    svg {
      position: relative;
      width: 25px;
      height: 25px;
      top: -50px;
      padding: 20px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
      transition: all 0.3s;
    }

    h5 {
      font: {
        size: 20px;
        weight: 600;
      }
      margin-top: -25px;
      margin-bottom: 25px;
    }

    p {
      color: #777;
      margin-bottom: 26px;

      a {
        color: #777;
        transition: all 0.3s;

        &:hover {
          color: #37a9d6;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      bottom: 15px;
      border-radius: 50%;
      background: linear-gradient(135deg, #37a9d6, #7bd0f2 70.71%);
      box-shadow: 0 0 15px 0px #37a9d6;
    }

    &:hover {
      svg {
        color: #37a9d6;
      }
    }
  }

  iframe {
    margin: 20px 0;
    border-radius: 10px;
  }
}

/* ------------------------------
		      3. About Page
------------------------------ */
.about {
  position: relative;
  padding: 50px 0 50px;

  .title {
    background-position: bottom center;
    background-repeat: no-repeat;
    padding-bottom: 15px;
    margin-bottom: 30px;

    h1 {
      color: #37a9d6;
    }
  }

  .item-work {
    position: relative;
    height: calc(100% - 30px);
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #ecf7fa;

    svg {
      position: relative;
      width: 25px;
      height: 25px;
      top: -50px;
      padding: 20px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
      transition: all 0.3s;
    }

    h5 {
      font: {
        size: 20px;
        weight: 600;
      }
      margin-top: -25px;
      margin-bottom: 25px;
    }

    p {
      color: #777;
      margin-bottom: 26px;

      a {
        color: #777;
        transition: all 0.3s;

        &:hover {
          color: #37a9d6;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      left: 50%;
      bottom: 15px;
      border-radius: 50%;
      background: linear-gradient(135deg, #37a9d6, #7bd0f2 70.71%);
      box-shadow: 0 0 15px 0px #37a9d6;
    }

    &:hover {
      svg {
        color: #37a9d6;
      }
    }
  }

  p {
    text-align: left;
  }

  ul {
    padding-left: 20px;

    li {
      position: relative;
      text-align: left;
      padding-left: 15px;

      &::after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        top: 7px;
        left: 0;
        border-radius: 50%;
        background-color: #000;
      }
    }
  }

  img {
    border-radius: 5px;
  }
}

.history {
  padding: 20px 0 50px;
  background-image: url(../../img/backgrounds/fees_bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .slick-slider {
    top: 0;
    right: -100px;

    .slick-next {
      width: 50px;
      height: calc(100% - 120px);
      top: calc(50% - 17px);
      left: -38px;
      bottom: 5px;
      border-radius: 5px;
      background: #ecf7fa;
      transition: all 0.3s;
      transform: translateY(-50%);
      z-index: 9;

      &::before {
        background-image: url(../../img/icon/angle-left-dark.svg);
      }
    }

    .slick-prev {
      display: none !important;
    }
  }

  p {
    text-align: left;
  }

  .text {
    color: #37a9d6;
    font: {
      size: 21px;
      family: "Caveat", sans-serif;
    }
  }

  ul {
    margin: 20px 0 30px 15px;

    li {
      display: flex;
      align-items: center;
      text-align: left;
      font-weight: 600;
      margin-bottom: 20px;

      svg {
        width: 15px;
        height: 15px;
        color: #37a9d6;
        padding: 10px;
        margin-right: 14px;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
        transition: all 0.3s;
      }

      &:hover {
        svg {
          color: #000;
        }
      }
    }
  }
}

/* ------------------------------
		      4. News Page
------------------------------ */
.news {
  padding: 50px 0 100px;

  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    overflow: hidden;
    margin: 0 20px 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

    .img {
      overflow: hidden;
      max-height: 280px;
      margin-bottom: 15px;
      border-radius: 10px;

      img {
        transition: all 0.3s;
      }
    }

    .info {
      position: relative;
      height: 37%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;

      span {
        position: absolute;
        display: flex;
        align-items: center;
        top: -34px;
        left: 50%;
        color: #37a9d6;
        padding: 10px 20px;
        border-radius: 30px;
        background-color: #fff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
        transform: translateX(-50%);

        svg {
          margin-right: 7px;
        }
      }

      h5 {
        font: {
          size: 18px;
          weight: 600;
        }
        margin: 20px 0;
        text-align: center;

        a {
          color: #000;
          transition: all 0.3s;

          &:hover {
            color: #37a9d6;
          }
        }
      }

      .btn-news {
        position: relative;
        display: flex;
        text-align: right;
        padding: 25px 20px 15px;
        align-items: center;

        a {
          position: relative;
          color: #626262;
          font-weight: 800;
          transition: all 0.3s;

          svg {
            width: 13px;
            height: 13px;
            margin-left: 6px;
            transition: margin-left 0.3s;
          }

          &:hover {
            color: #37a9d6;

            svg {
              margin-left: 12px;
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: calc(100% + 20px);
          height: 2px;
          top: 0;
          left: -10px;
          background-color: #f4f4f4;
        }
      }
    }

    &:hover {
      transition: all 0.3s;

      .img {
        img {
          transition: all 0.3s;
          transform: scale(1.1);
        }
      }
    }
  }

  &.page {
    h2 {
      text-align: left;
      margin-bottom: 30px;
    }

    .date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      border: 1px solid #f4f4f4;

      span {
        display: flex;
        align-items: center;
        color: #a8a8a8;
        padding: 15px;
        text-align: left;

        svg {
          margin-right: 5px;
        }
      }
    }

    p {
      font-size: 15px;
      text-align: left;
      margin-top: 30px;
    }

    .title {
      align-items: flex-start;
      margin: 50px 0 30px;

      h3 {
        display: flex;
        align-items: center;
        color: #37a9d6;

        svg {
          padding: 15px;
          margin: 0 10px 0 0;
        }
      }
    }

    .img {
      overflow: hidden;
      border-radius: 5px;
      margin-bottom: 30px;

      img {
        border-radius: 5px;
        transition: all 0.3s;
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    .video {
      padding: 10px;
      border-radius: 10px;
      background: #ffffff;
      border: 2px solid #ebebeb;

      iframe {
        border-radius: 10px;
      }
    }

    .item-left {
      padding: 15px;
      margin: 0 20px 40px;
      border-radius: 5px;
      border: 1px solid #f4f4f4;

      h5 {
        position: relative;
        font-weight: 600;
        text-align: left;
        margin: 10px 0 20px;

        &::after {
          content: "";
          position: absolute;
          width: 49px;
          height: 20px;
          top: 12px;
          left: 0;
          border-radius: 50%;
          background: #37a9d6;
          transform: translateY(-50%) rotate(-7deg);
          z-index: -1;
          opacity: 0.2;
        }
      }

      .post {
        display: flex;
        align-items: center;
        margin: 15px 0;

        .image {
          padding: 5px;
          margin-right: 15px;
          border-radius: 10px;
          border: 2px solid #f5f5f5;

          img {
            max-width: 70px;
            border-radius: 5px;
          }
        }

        .info {
          display: flex;
          flex-direction: column;

          a {
            position: relative;
            color: #000;
            font: {
              size: 15px;
              weight: 700;
            }
            text-align: left;
            padding-bottom: 10px;
            margin-bottom: 10px;
            transition: all 0.3s;

            &::after {
              content: "";
              position: absolute;
              width: 22px;
              height: 2px;
              left: 0;
              bottom: 0;
              background-color: #37a9d6;
            }

            &::before {
              content: "";
              position: absolute;
              width: 5px;
              height: 2px;
              bottom: 0;
              left: 25px;
              border-radius: 50%;
              background-color: #37a9d6;
            }

            &:hover {
              color: #37a9d6;
            }
          }

          span {
            font-size: 13px;
            color: #a8a8a8;
            text-align: left;

            svg {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }
          }
        }
      }

      .donat-left {
        img {
          border-radius: 10px;
        }

        p {
          text-align: center;
          margin: 20px 0;
        }

        .btn-groups {
          justify-content: center;

          .btn-st {
            padding: 5px 10px;

            svg {
              width: 15px;
              height: 15px;
              margin-right: 7px;
              animation: scale 1s alternate infinite ease-in;
            }
          }
        }
      }
    }
  }
}

/* ------------------------------
		        5. NFT Page
------------------------------ */
.nft {
  position: relative;
  padding: 100px 0;

  p {
    text-align: left;
  }

  .block {
    position: relative;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

    h6 {
      position: relative;
      font-weight: 600;
      text-align: left;
      margin-bottom: 30px;
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        width: 49px;
        height: 20px;
        top: 12px;
        left: 0;
        border-radius: 50%;
        background: #37a9d6;
        transform: translateY(-50%) rotate(-7deg);
        opacity: 0.2;
        z-index: -1;
      }

      img {
        max-width: 20px;
        margin: 0 2px 0 5px;
      }
    }

    .img {
      max-width: 270px;
      margin-bottom: 30px;
    }

    .btn-groups {
      justify-content: center;

      .btn-st {
        padding: 5px 15px;
      }
    }
  }

  .info {
    padding-left: 40px;

    h4 {
      font: {
        size: 30px;
        family: "Caveat", sans-serif;
      }
      color: #37a9d6;
      text-align: left;
      margin-bottom: 0;

      img {
        margin-right: 5px;
      }

      svg {
        color: #ff5a5a;
        width: 20px;
        height: 20px;
        margin-left: 7px;
        animation: scale 1s alternate infinite ease-in;
      }
    }
  }

  .gallery {
    padding: 100px 0;

    .item {
      padding: 20px;
      margin-bottom: 40px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
      transition: all 0.3s;

      img {
        border-radius: 10px;
      }

      .desc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 10px 15px;

        .name {
          text-align: left;

          h4 {
            font-weight: 600;
            margin-top: 10px;
            transition: all 0.3s;
          }
        }

        .price {
          text-align: right;

          h4 {
            display: flex;
            align-items: center;
            font: {
              size: 16px;
              weight: 600;
            }
            margin-top: 10px;

            img {
              max-width: 10px;
              margin-left: 5px;
            }
          }
        }

        span {
          color: #777;
          font: {
            size: 16px;
            family: "Caveat", sans-serif;
          }

          img {
            max-width: 16px;
            margin-left: 2px;
            border-radius: 0;
          }
        }
      }

      .btn-groups {
        position: relative;
        padding-top: 20px;
        justify-content: center;

        &::after {
          content: "";
          position: absolute;
          width: calc(100% + 40px);
          height: 2px;
          top: 0;
          left: -20px;
          background-color: #f4f4f4;
        }

        .btn-buy {
          position: relative;
          color: #626262;
          font-size: 16px;
          font-weight: 800;
          transition: all 0.3s;

          svg {
            margin-right: 7px;
          }

          &:hover {
            color: #37a9d6;
          }
        }
      }

      &:hover {
        transform: translateY(-5px);

        .name {
          h4 {
            color: #37a9d6;
          }
        }
      }
    }
  }
}

/* ------------------------------
		        6. Footer
------------------------------ */
footer {
  padding: 50px 0;
  background-color: #ecf7fa;

  h5 {
    position: relative;
    text-align: left;
    margin-bottom: 25px;
  }

  .left-side {
    display: flex;
    flex-direction: column;

    .logo {
      max-width: 150px;
      margin-bottom: 20px;
    }

    p {
      color: #777;
      text-align: left;
    }

    .btn-st {
      padding: 7px 10px;
    }
  }

  .center-side {
    ul {
      li {
        position: relative;
        padding-left: 15px;
        text-align: left;
        margin-bottom: 10px;

        a {
          color: #444444;
          font: {
            size: 15px;
            weight: 600;
          }
          transition: all 0.3s;

          &:hover {
            color: #37a9d6;
          }
        }

        &::after {
          content: "";
          position: absolute;
          width: 5px;
          height: 5px;
          top: 7px;
          left: 0;
          border-radius: 50%;
          background: linear-gradient(135deg, #37a9d6, #7bd0f2 70.71%);
          box-shadow: 0 0 15px 0px #37a9d6;
        }
      }
    }

    p {
      color: #777;
      text-align: left;
      margin-bottom: 0;
    }

    .social {
      display: flex;
      align-items: center;
      margin-top: 20px;

      a {
        svg {
          width: 20px;
          height: 20px;
          padding: 8px;
          margin-right: 15px;
          border-radius: 50%;
          background-color: #fff;
          box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
          transition: all 0.3s;
        }

        &:hover {
          svg {
            color: #000;
          }
        }
      }
    }
  }

  .right-side {
    p {
      color: #777;
      text-align: left;
      margin-bottom: 0;
    }
  }
}

/* ------------------------------
		      7. Page Banner
------------------------------ */
.page-banner {
  position: relative;
  padding: 150px 0 50px;
  background-image: url(../../img/backgrounds/page_banner_bg.jpg);
  background-size: cover;
  background-position: center center;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .container {
    position: relative;
    z-index: 1;

    h1 {
      color: #fff;
    }

    .breadcrumb {
      justify-content: center;

      li {
        a {
          transition: all 0.3s;

          &:hover {
            color: #fff;
          }
        }

        &.active {
          color: #fff;
          padding: 0;
          margin-left: 5px;
        }

        &::before {
          content: "";
          width: 18px;
          height: 18px;
          padding: 0;
          margin-right: 5px;
          background-image: url(../../img/icon/other.svg);
          transform: rotate(30deg);
          opacity: 0.8;
        }
      }
    }
  }
}

/* Kindergarten */
.kind {
  .title {
    svg {
      width: 20px;
      height: 20px;
      color: #37a9d6;
      padding: 20px;
      border-radius: 50%;
      background-color: #f2fbfd;
      margin-bottom: 30px;
    }
  }

  .k-stat {
    position: relative;
    padding: 70px 0 100px;

    .title {
      margin-bottom: 30px;

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        font-weight: 700;

        img {
          max-width: 20px;
          margin-bottom: 10px;
        }
      }
    }

    .widget {
      position: relative;

      .video {
        width: 90% !important;
        height: auto !important;
        margin: auto;

        video {
          border-radius: 5px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 98%;
        height: 110%;
        top: 50%;
        left: 50%;
        border-radius: 15px;
        border: 2px dashed #37a9d6;
        transform: translate(-50%, -51%);
        z-index: -1;
      }

      .shape-2 {
        position: absolute;
        top: 0;
        left: -15px;
        transform: rotateZ(316deg);

        img {
          width: 100px;
          height: 20px;
        }
      }
    }

    .info {
      margin-bottom: 30px;
      h4 {
        position: relative;
        font-weight: 600;
        text-align: left;
        margin: 20px 0;
        font-size: 20px;

        &::after {
          content: "";
          position: absolute;
          width: 49px;
          height: 20px;
          top: 12px;
          left: 0;
          border-radius: 50%;
          background: #37a9d6;
          transform: translateY(-50%) rotate(-7deg);
          z-index: -1;
          opacity: 0.2;
        }
      }

      .date {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        gap: 7px;
        font-weight: 600;
        color: #a8a8a8;

        svg {
          position: relative;
          top: -1px;
        }
      }

      .content-wrapper {
        margin-top: -20px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 40px 20px 20px;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.05);

        .content {
          position: relative;
          text-align: left;
          min-height: 300px;
          max-height: 300px;
          overflow: hidden;
          transition: max-height 0.5s ease-in-out;

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 90px;
            left: 0;
            bottom: 0;
            opacity: 1;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0),
              #ffffff
            );
            transition: all 0.3s;
          }

          &.expanded {
            max-height: 1000px;

            &::after {
              opacity: 0;
              transition: all 0.3s;
            }
          }
        }
      }

      .btn-st {
        justify-content: center;

        svg {
          position: relative;
          // top: -3px;
          margin-right: 7px;
        }
      }
    }

    .video {
      padding: 10px;
      border-radius: 10px;
      background: #ffffff;
      border: 2px solid #ebebeb;

      iframe {
        border-radius: 10px;
      }
    }

    .stat-item {
      position: relative;
      margin-bottom: 50px;

      .icon {
        svg {
          width: 20px;
          height: 20px;
          color: #37a9d6;
          padding: 20px;
          border-radius: 50%;
          background-color: #f2fbfd;
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 16px;
        margin-bottom: 30px;
      }

      .player {
        position: relative;

        .video {
          width: 90% !important;
          height: 300px !important;
          margin: auto;

          iframe {
            border-radius: 5px;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 95%;
          height: 105%;
          top: 51%;
          left: 50%;
          border-radius: 10px;
          border: 2px dashed #37a9d6;
          transform: translate(-50%, -51%);
          z-index: -1;
        }
      }
    }
  }

  .k-direct {
    h4 {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      font-weight: 600;

      svg {
        color: #fff;
        width: 25px;
        height: 25px;
        padding: 7px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #47d071;
      }

      span {
        color: #37a9d6;
        font-size: 31px;
        font-family: "Caveat", sans-serif;
        margin-left: 10px;
        margin-bottom: 5px;
      }
    }

    .sub-info {
      position: relative;
      margin: 20px 0;

      span {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 20px;

        svg {
          width: 20px;
          height: 20px;
          color: #37a9d6;
          margin: 0 20px 0 10px;
        }

        &::after {
          content: "";
          position: absolute;
          width: 41px;
          height: 20px;
          top: 12px;
          left: 0;
          border-radius: 50%;
          background: #37a9d6;
          -webkit-transform: translateY(-50%) rotate(-7deg);
          transform: translateY(-50%) rotate(-7deg);
          z-index: 0;
          opacity: 0.2;
        }
      }
    }

    .content {
      position: relative;
      text-align: left;
      margin-top: 40px;

      p {
        font-size: 18px;
      }

      ul {
        li {
          display: flex;
          align-items: center;
          margin: 10px 0;
          font-size: 16px;
          font-weight: 600;

          svg {
            color: #47d071;
            width: 12px;
            height: 12px;
            padding: 8px 8px 8px 2px;
            margin-right: 5px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
    }

    .widget {
      position: relative;

      img {
        margin: 0 15px;
        max-width: 44%;
        border-radius: 10px;

        &:nth-child(1) {
          margin-top: 130px;
        }

        &:nth-child(2) {
          margin-top: -130px;
        }
      }

      .shape {
        position: absolute;
      }

      &::before {
        content: "";
        position: absolute;
        width: 60%;
        height: 80%;
        top: 50%;
        left: 50%;
        border-radius: 15px;
        border: 2px dashed #37a9d6;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }

    .slick-slider {
      top: 0;
      right: 0;

      .slick-next {
        width: 50px;
        height: calc(100% - 120px);
        top: calc(50% - 17px);
        left: -38px;
        bottom: 5px;
        border-radius: 5px;
        background: #ecf7fa;
        transition: all 0.3s;
        transform: translateY(-50%);
        z-index: 9;

        &::before {
          background-image: url(../../img/icon/angle-left-dark.svg);
        }
      }

      .slick-prev {
        display: none !important;
      }
    }

    .widget-2 {
      position: relative;

      .video {
        width: 90% !important;
        height: auto !important;
        margin: auto;

        video {
          border-radius: 5px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 98%;
        height: 110%;
        top: 50%;
        left: 50%;
        border-radius: 15px;
        border: 2px dashed #37a9d6;
        transform: translate(-50%, -51%);
        z-index: -1;
      }

      .shape-2 {
        position: absolute;
        top: 0;
        left: -15px;
        transform: rotateZ(316deg);

        img {
          width: 100px;
          height: 20px;
        }
      }
    }
  }

  .k-donat {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #fff;
      opacity: 0.5;
    }

    .container {
      position: relative;
      z-index: 1;
    }

    .title {
      margin-bottom: 50px;
    }

    .tabs {
      margin-bottom: 50px;

      .switch-button {
        position: relative;
        border-radius: 30px;
        overflow: hidden;
        width: 260px;
        text-align: center;
        font-size: 18px;
        letter-spacing: 1px;
        color: #fff;
        padding-right: 130px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
        margin: auto;
        background: #fff;
        color: #444444;
        font-weight: 700;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);

        .switch-monthly {
          color: #444444;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          font-weight: 700;
          width: 135px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 3;
          pointer-events: none;
        }

        &-checkbox {
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 2;

          &:checked + .switch-button-label:before {
            transform: translateX(125px);
            transition: transform 300ms linear;
          }

          & + .switch-button-label {
            position: relative;
            padding: 15px 0;
            display: block;
            user-select: none;
            pointer-events: none;

            &:before {
              content: "";
              background: #37a9d6;
              height: 100%;
              width: 100%;
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 30px;
              transform: translateX(0);
              transition: transform 300ms;
            }

            .switch-button-label-span {
              position: relative;
            }
          }
        }
      }
    }

    .crypto {
      position: relative;
      display: flex;
      justify-content: center;

      svg {
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .amount {
      label {
        position: relative;

        input {
          position: absolute;
          visibility: hidden;

          &:checked + span {
            color: #fff;
            border: 2px solid #37a9d6;
            background: #37a9d6;

            svg {
              g path {
                fill: #fff;
              }
            }
          }
        }

        span {
          cursor: pointer;
          position: relative;
          display: flex;
          align-items: center;
          font-size: 22px;
          font-weight: 700;
          padding: 10px 30px;
          margin: 20px 10px;
          background: #fff;
          border-radius: 40px;
          border: 2px solid transparent;
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
          transition: all 0.3s;

          svg {
            position: relative;
            top: -2px;
            margin-left: 7px;
          }
        }
      }
    }

    .btn-st {
      flex-direction: column;
      margin-top: 50px;
      padding: 15px 30px;
      border-radius: 70px;
      margin-right: 0;

      img {
        max-width: 70px;
        margin-bottom: 10px;
      }
    }
  }

  .logo-pay {
    position: relative;
    display: block;
    padding: 50px 0;
    background-color: #fff;
    z-index: 10000;

    img {
      max-width: 300px;
    }
  }
}
