@keyframes scale {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes fadeIn {
  50%,
  0%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
