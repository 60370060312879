/* xxl, large: 1400px */
$breakpoint-xxl: 1400px;
/* xl, large: 1200px */
$breakpoint-xl: 1200px;
/* lg, large: 991px */
$breakpoint-lg: 991px;
/* md, large: 768px */
$breakpoint-md: 768px;
/* sm, large: 575px */
$breakpoint-sm: 575px;

@media screen and (max-width: $breakpoint-xxl) {
  .kind {
    .k-direct {
      .widget {
        img {
          max-width: 42%;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .navbar-custom {
    .navbar-nav {
      a.link {
        font-size: 14px;
        padding: 40px 10px;
      }
    }
  }
  .donat {
    .points {
      span {
        &:nth-child(1) {
          top: 42%;
          left: 13%;
        }

        &:nth-child(2) {
          bottom: 26%;
          left: 27%;

          img {
            width: 25px;
            height: 25px;
          }
        }

        &:nth-child(3) {
          top: 37%;
          left: 47%;

          img {
            width: 20px;
            height: 20px;
          }
        }

        &:nth-child(4) {
          top: 43%;
          right: 26%;

          img {
            width: 20px;
            height: 20px;
          }
        }

        &:nth-child(5) {
          bottom: 23%;
          right: 6%;
        }
      }
    }
  }

  .help .form-custom {
    &::after {
      width: 310px;
      height: 285px;
      right: -81px;
    }
  }

  .kind {
    .k-stat {
      .info {
        .item {
          p {
            font-size: 16px;
            text-align: left;
          }
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .navbar-light {
    .navbar-toggler {
      position: absolute;
      top: 32px;
      left: 50%;
      transform: translateX(-50%);
      border-color: #37a9d6;
    }

    .navbar-toggler-icon {
      background-image: url(../../img/icon/menu.svg);
    }
  }

  .navbar-custom {
    .navbar-nav {
      margin: 0;
      align-items: flex-start;

      a.link {
        padding: 20px 25px;

        &.active {
          &::after {
            width: 2px;
            height: calc(100% - 25px);
            left: 17px;
            top: 20%;
            bottom: unset;
          }
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      left: -60px;
    }
  }

  .navbar-collapse {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100px;
    background: rgba(0, 0, 0, 0.85);
  }

  .banner {
    .container {
      h1,
      p {
        text-align: center;
      }
    }

    .btn-groups {
      justify-content: center;
    }
  }

  .company {
    .widget {
      margin-bottom: 60px;
    }

    .video {
      height: 400px;
    }
  }

  .our-help {
    margin-bottom: 160px;

    .slick-slider {
      top: 60px;

      .slick-prev,
      .slick-next {
        bottom: -80px;
      }

      .slick-prev {
        left: 41%;
      }

      .slick-next {
        left: 52%;
      }
    }
  }

  .help {
    .form-custom {
      margin-bottom: 50px;

      &::after {
        display: none;
      }
    }

    ul {
      padding-left: 0;

      li {
        padding-left: 0;

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(3) {
          padding-left: 0;
        }
      }
    }
  }

  .order-info-2 {
    order: 2;
  }

  .about {
    .item-work {
      height: calc(100% - 60px);
      margin-bottom: 60px;
    }
  }

  .history {
    .slick-slider {
      right: 0;

      .slick-next {
        display: none !important;
      }
    }
  }

  .news {
    &.page {
      .col-lg-4 {
        display: flex;
      }
      .item-left {
        width: 50%;
        margin: 20px 10px 40px;
      }
    }
  }

  .contact {
    .info {
      height: calc(100% - 60px);
      margin-bottom: 60px;
    }
  }

  .nft {
    .block {
      margin-bottom: 50px;
    }
  }

  footer {
    .left-side,
    .center-side {
      margin-bottom: 40px;
    }

    .right-side {
      h5,
      p {
        text-align: center;
      }
    }
  }

  .kind {
    .k-stat {
      .col-lg-5 {
        order: 2;
      }

      .info {
        margin-bottom: 50px;

        .subtitle {
          flex-direction: column;

          .shape-1 {
            margin-top: 30px;
          }
        }
      }
    }

    .k-direct {
      .container {
        .mt-150 {
          margin-top: 100px !important;
        }

        .row {
          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(7) {
            .col-lg-5 {
              order: 2;
            }

            .pl-30 {
              padding-left: calc(var(--bs-gutter-x) * 0.5);
            }
          }

          &:nth-child(2),
          &:nth-child(4) {
            .widget {
              margin-top: 150px !important;
            }
          }

          &:nth-child(3) {
            .widget {
              margin-top: 100px;
            }
          }

          &:nth-child(6) {
            .widget {
              img {
                max-width: 30% !important;
              }

              &::before {
                width: 38%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .navbar-custom {
    .navbar-brand {
      img {
        max-width: 90px;
      }
    }
  }

  .banner {
    .container {
      h1 {
        font-size: 32px;

        span {
          font-size: 26px;
        }
      }

      p {
        font-size: 13px;
      }
    }

    .btn-groups {
      flex-direction: column;

      .btn-st {
        margin: 0 0 20px;
      }
    }
  }

  .company {
    .widget {
      p {
        right: 20px;

        span {
          font-size: 14px;

          b {
            font-size: 13px;
          }
        }
      }
    }

    .info {
      .work {
        span {
          &:last-child {
            margin-bottom: 30px;
          }
        }
      }
    }

    .mb-70 {
      margin-bottom: 0 !important;
    }
  }

  .donat {
    .points {
      display: none;
    }
  }

  .about {
    padding: 50px 0 50px;
  }

  .history {
    padding: 0;
  }

  .news {
    &.page {
      .col-lg-4 {
        display: block;
      }
      .item-left {
        width: 100%;
        margin: 20px 0 40px;
      }
    }
  }

  footer {
    .left-side,
    .center-side {
      align-items: center;
      margin-bottom: 40px;

      h5,
      p {
        text-align: center;
      }

      ul {
        li {
          text-align: center;
          padding-left: 0;

          &::after {
            display: none;
          }
        }
      }

      .social {
        justify-content: center;
      }
    }

    .right-side {
      h5,
      p {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .kind {
    .k-banner {
      padding: 200px 0 100px;
    }

    .k-stat {
      padding: 70px 0 50px;

      .info {
        .subtitle {
          .shape-1 {
            img {
              width: 120px;
              height: 120px;
            }
          }
        }
      }
    }

    .k-direct {
      h4 {
        flex-direction: column;

        svg {
          margin-right: 0;
          margin-bottom: 15px;
        }

        span {
          margin-left: 0;
        }
      }

      .sub-info {
        span {
          font-size: 14px;
          text-align: left;

          &::after {
            top: 50%;
          }
        }
      }

      .content {
        p {
          font-size: 14px !important;
        }

        ul {
          li {
            font-size: 14px;
          }
        }
      }

      .widget {
        img {
          max-width: 40%;
        }
      }

      .container {
        .row {
          &:nth-child(2) {
            .widget {
              margin-top: 80px !important;

              .shape {
                left: 0 !important;
                top: -20px !important;
                width: auto !important;
                height: auto !important;
              }
            }
          }

          &:nth-child(3) {
            .widget {
              img:nth-child(1) {
                margin-top: 40px;
              }

              .shape {
                top: 30px !important;
                left: calc(50% - 60px) !important;
                width: 90px !important;
                height: 120px !important;
              }
            }
          }

          &:nth-child(4) {
            .widget {
              margin-top: 50px !important;

              img:nth-child(2) {
                margin-top: -5px;
              }

              .shape {
                width: auto !important;
                height: auto !important;
                top: -10px !important;
              }
            }
          }

          &:nth-child(6) {
            .widget {
              img {
                max-width: 55% !important;
              }
            }
          }
        }
      }
    }
  }

  .top-bar {
    .info {
      .mail {
        ul {
          li {
            &:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
  }
}
